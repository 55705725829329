$header-height: 52px;
$tc-header-height: 72px;

$default-transition-time: 225ms;
$default-transition-function: cubic-bezier(.4, 0, .2, 1);

$gray-palette: (
  100 : #D2D5D9,
  200 : #A5A8AE,
  300 : #8E96A5,
  400 : #717988,
  500 : #434C58,
  600 : #272F39,
  700 : #111424,
);

//breakpoints
$screen-small-mobile-min: 320px;
$screen-mobile-min: 640px;
$screen-tablet-min: 768px;
$screen-desktop-min: 1024px;
$screen-desktop-wide-min: 1366px;

@mixin small-mobile-down {
  @media (max-width: -1px + $screen-mobile-min ) {
    @content;
  }
}

@mixin small-mobile {
  @media (max-width: -1px + $screen-mobile-min -1px) {
    @content;
  }
}

@mixin mobile-up {
  @media (min-width: $screen-mobile-min) {
    @content;
  }
}

@mixin mobile-down {
  @media (max-width: -1px + $screen-tablet-min ) {
    @content;
  }
}

@mixin mobile {
  @media (min-width: $screen-mobile-min) and (max-width: -1px + $screen-tablet-min) {
    @content;
  }
}

@mixin tablet-up {
  @media (min-width: $screen-tablet-min) {
    @content;
  }
}

@mixin tablet-down {
  @media (max-width: -1px + $screen-desktop-min) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $screen-tablet-min) and (max-width: -1px + $screen-desktop-min) {
    @content;
  }
}

@mixin desktop-up {
  @media (min-width: $screen-desktop-min) {
    @content;
  }
}

@mixin desktop-down {
  @media (max-width: -1px + $screen-desktop-wide-min) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $screen-desktop-min) and (max-width: -1px + $screen-desktop-wide-min) {
    @content;
  }
}

@mixin desktop-wide-up {
  @media (min-width: $screen-desktop-wide-min) {
    @content;
  }
}

$white: #fff;
$blue: #2D9CDB;
$blue-dark: #1283C9;
$warning-red: #F04438;

$green-main: #59AB8A;
$green-light: #61BA96;
$green-dark: #50997B;
$green-bckg: rgba(25, 145, 74, 0.05);

$purple-primary: #A190FE;

$co-box-shadow: 0 2px 4px -1px rgba(161, 144, 254, 20%), 0 4px 5px 0 rgb(161, 144, 254, 14%), 0 1px 10px 0 rgb(161, 144, 254, 12%);

$gray-lightest-alt: map-get($gray-palette, 100); // #D2D5D9
$gray-lightest: map-get($gray-palette, 200); // #A5A8AE
$gray-lighter: map-get($gray-palette, 300); // #8E96A5
$gray-light: map-get($gray-palette, 400); // #717988
$gray: map-get($gray-palette, 500); // #434C58
$gray-dark: map-get($gray-palette, 600); // #272F39
$gray-darker: map-get($gray-palette, 700); // #111424

$border-gray: #E7E9EE;

$black: #0F1115;
$gray-900: #181B22;
$gray-800: #2C333F;
$gray-700: #545F78;
$gray-600: #707D99;
$gray-500: #9AA3B7;
$gray-400: #C3C9D5;
$gray-300: #D8DCE3;
$gray-200: #F3F4F7;
$gray-100: #F9FAFB;

$green-900: #0a1410;
$green-800: #183328;
$green-700: #26513f;
$green-600: #356f57;
$green-500: #458d70;
$green-400: #59ab8a;
$green-300: #78c7a7;
$green-200: #9fe3c8;
$green-100: #ccffeb;

