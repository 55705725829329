$base: 4px;

$spaceamounts: (0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48);

@each $space in $spaceamounts {
  .m-#{$space} {
    margin: #{$space}px;
  }

  .mx-#{$space} {
    margin-left: #{$space}px;
    margin-right: #{$space}px;
  }

  .my-#{$space} {
    margin-top: #{$space}px;
    margin-bottom: #{$space}px;

  }

  .ml-#{$space} {
    margin-left: #{$space}px;
  }

  .mr-#{$space} {
    margin-right: #{$space}px;
  }

  .mb-#{$space} {
    margin-bottom: #{$space}px;
  }

  .mt-#{$space} {
    margin-top: #{$space}px;
  }

  .p-#{$space} {
    padding: #{$space}px;
  }

  .px-#{$space} {
    padding-left: #{$space}px;
    padding-right: #{$space}px;
  }

  .py-#{$space} {
    padding-top: #{$space}px;
    padding-bottom: #{$space}px;
  }

  .pl-#{$space} {
    padding-left: #{$space}px;
  }

  .pr-#{$space} {
    padding-right: #{$space}px;
  }

  .pb-#{$space} {
    padding-bottom: #{$space}px;
  }

  .pt-#{$space} {
    padding-top: #{$space}px;
  }

  .gg-#{$space} {
    grid-gap: #{$space}px;;
  }
}

.mt-a {
  margin-top: auto;
}

.mr-a {
  margin-right: auto;
}

.mb-a {
  margin-bottom: auto;
}

.ml-a {
  margin-left: auto;
}

.w-100 {
  width: 100%;
}
