@import "variables";

.xln {

  /*
  stepper
  */

  .mat-stepper-horizontal {
    .mat-horizontal-stepper-header-container {
      display: none !important;
    }
  }

  /*
  mat-cards
   */

  mat-card.mat-mdc-card:not([class*=mat-elevation-z]) {
    box-shadow: none;
  }

  /*
  paginator
   */

  .mat-paginator {
    .mat-paginator-container {
      justify-content: center;
    }
  }

  /*
  mat-progress-bar
   */

  .mat-mdc-progress-bar.with-border {
    height: 9px;
    border-width: 1px;
    border-style: solid;

    .mat-mdc-progress-bar-background {
      fill: #ffffff;
    }

    .mat-mdc-progress-bar-buffer {
      background-color: #ffffff;
    }
  }

  /*
  mat table
   */
  .mat-mdc-table th, .mat-mdc-table td {
    vertical-align: middle;
  }


  /*
  mat tab
   */
  .mat-mdc-tab-link.mdc-tab {
    letter-spacing: normal;
  }

  .mat-mdc-tab-group.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header .mat-mdc-tab {
    flex-grow: unset;
    letter-spacing: normal;
  }

  nav.menu-tab.mat-tab-nav-bar {
    border-bottom: none;
    margin-left: -16px;

    .mat-tab-link {
      position: relative;
      padding: 0 16px;
      min-width: 0;
      opacity: 1;
      font-weight: 400;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: normal;

      &.active {
        font-weight: 600;
      }

      &.active:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 16px;
        right: 16px;
        box-shadow: inset 0 -2px;
      }

    }
  }

  /*
  button
   */

  .mat-mdc-raised-button,
  .mat-mdc-flat-button,
  .mat-mdc-stroked-button {
    font-size: 12px;
    border-radius: 4px;
  }

  .mdc-button {
    min-width: 40px;
  }

  .mat-mdc-button>.mat-icon {
    margin-right: 0;
    height: 20px;
    width: 20px;
    display: flex;
    flex-shrink: 0;
  }

  /*
  slide-toggle
   */

  .mdc-switch__icon {
    display: none;
  }

  /*
  mat menu
   */

  div.mat-mdc-menu-panel {
    max-width: 420px;
    max-height: 600px;
    min-height: 54px;
  }

  .tpt-more-menu {
    width: 157px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    border-radius: 5px;

    &.card-menu {
      width: unset;
    }

  }

  .tpt-more-menu.priority-menu {

    .mat-mdc-menu-content:not(:empty) {
      padding-bottom: 8px;
    }

  }
}

/*
mat-dialog
 */

.mat-mdc-dialog-container {
  box-shadow: none !important;
}

.mat-mdc-dialog-container {
  --mdc-dialog-supporting-text-font: 'Manrope', 'Inter', Roboto, 'Helvetica Neue', sans-serif;
  --mdc-dialog-subhead-font: 'Manrope', 'Inter', Roboto, 'Helvetica Neue', sans-serif;
  --mdc-dialog-supporting-text-tracking: normal;
  --mdc-dialog-supporting-text-line-height: normal;
  --mdc-dialog-container-shape: 8px;
  --mdc-shape-medium: 8px;

  .mat-mdc-dialog-content {
   max-height: 100vh;
  }
}

.mdc-dialog .mdc-dialog__content {
  padding: 24px;
}


/*
mat-icon
 */

.mat-icon {
  overflow: visible !important;
}

.mat-mdc-select-panel {
  background: #fff;
}

.mat-mdc-form-field-infix {
  min-height: 40px !important;
  width: unset !important;
}

.mat-mdc-select-panel .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-multiple) {
  background: rgba(0, 0, 0, 0.04) !important;
}

.mat-select-value {
  vertical-align: middle;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start {
  border-width: 1px !important;
}

.subhead {
  font-weight: bold;
  font-size: 18px;
  color: $gray;
}

.subhead-1 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $gray-darker;
}

.regular-18-24 {
  font-size: 18px;
  line-height: 24px;
  color: $gray-darker;
  font-weight: 400;
}

.regular-16-24 {
  font-size: 16px;
  line-height: 24px;
  color: $gray-dark;
  font-weight: 400;
}

.regular-14-20 {
  font-size: 14px;
  line-height: 20px;
  color: $gray;
  font-weight: 400;
}

.caption {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: $gray-600;
}

.headline {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: $gray;
}

.headline-18-24 {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: $gray-darker;
}

.heading {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: $gray-darker;

  @include mobile-down {
    font-size: 20px;
    line-height: 28px;
  }
}

.regular {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $gray;
}

.gray-light {
  color: $gray-light;
}

.chat-dialog-align {
  transform: translateX(170px);
}

.chat-user-info-align {
  transform: translateX(170px);
}

.mat-selection-list {
  outline: none;
}

.mdc-snackbar {
  .mdc-snackbar__surface {
    padding-right: 0;
    box-shadow: none;
    background: transparent;
  }

  .mdc-snackbar__label {
    padding: 0;
  }
}

.default-snackbar {
  padding: 0 !important;
  box-shadow: none !important;

  @include mobile-down {
    margin-bottom: 40px !important;
  }
}
.small-snackbar {
  min-width: unset !important;
}

.mat-mdc-autocomplete-panel {
  max-height: 240px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.mat-mdc-input-element {
  caret-color: $gray-dark !important;
  box-sizing: border-box;
}

.menu-overlay {
  width: 360px;
  transform: translateY(-12px);
  box-shadow: 0 8px 8px rgba(8, 9, 78, 0.05), 0 0 2px rgba(8, 9, 78, 0.05) !important;
}

.contact-mobile-dialog {
  max-width: unset !important;

  .mat-icon {
    color: white !important;
  }
}

.custom-select-search {
  max-height: 332px !important;
  position: relative;
  top: 0;

  &.mat-mdc-select-panel {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  }

  &.search {
    max-width: 254px;
  }

  &.dialog {
    max-width: 200px;

    .mat-option-text {
      font-size: 14px;
    }
  }
  .mat-option {
    font-size: 16px !important;
  }
}

.filter-dialog, .pay-sbp-dialog {
  .mat-dialog-container {
    padding: 0;
  }
}

.help-center-dialog {
  .mat-dialog-container {
    @include mobile-down {
      padding: 16px;
    }
  }

  @include mobile-down {
    margin-top: 0 !important;
    height: var(--app-height) !important;
  }
}

.mdc-slider {
  --mdc-slider-handle-height: 16px;
  --mdc-slider-handle-width: 16px;
  --mdc-slider-inactive-track-height: 3px;
  --mdc-slider-active-track-height: 3px;

  .mat-ripple {
    display: none;
  }

  .mdc-slider__thumb-knob {
    border-width: 2px !important;
    background-color: #FFF !important;
  }
}

.header__menu {
  width: 200px;

  &.creative-objects {
    box-shadow: 0 2px 4px -1px rgba(161, 144, 254, 20%), 0 4px 5px 0 rgb(161, 144, 254, 14%), 0 1px 10px 0 rgb(161, 144, 254, 12%);
  }
}

.mat-mdc-menu-panel.creative-objects {
  .mat-mdc-menu-item:disabled {
    opacity: 0.6;
  }
}

.mat-mdc-menu-panel {
  min-height: 48px !important;
  max-width: 420px !important;
  background: $white;
}


.mat-mdc-menu-item {
  font-size: 14px !important;
  color: $gray-900 !important;
  line-height: 44px !important;
  height: 44px !important;
  min-height: 44px !important;

  .mdc-list-item__primary-text, .mat-mdc-menu-item-text {
    display: flex;
  }

  .mat-icon {
    display: flex;
    margin-right: 8px !important;
  }

  &.red-item {
    color: #F04438 !important;

    .mdc-list-item__primary-text {
      color: #F04438 !important;
    }
  }

  &.menu-item-delete {
    color: $warning-red !important;

    &:hover {
      color: $warning-red !important;
      transition: all 300ms;
    }
  }

  &:hover {
    transition: all 300ms;
    background: $gray-100 !important;
  }

  &:disabled {
    opacity: 0.5;

    &:hover {
      background: $white !important;
    }
  }
}

.header-profile-menu {
  .mat-menu-item {
    font-size: 16px !important;
  }
}

.more-menu.creative-objects {
  box-shadow: $co-box-shadow;
}

input.country-search {
  outline: none;
}

.country-list-button {
  font-size: 14px !important;
  color: $gray-darker !important;
  background: $white !important;
}

.arbitration-panel {
  position: relative;
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.w-100 {
  width: 100%;
}

.justify-center {
  justify-content: center;
}

.tpt-help-center .mat-mdc-dialog-container {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}

.select-panel-custom {
  position: relative;
  top: 52px;
  left: 16px;
  min-width: 100% !important;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: $green-main;
}

.detected-link {
  color: $green-main;
  text-decoration: none;

  &:visited {
    color: #551A8B;
  }
}

.mat-mdc-button-focus-overlay {
  background-color: transparent !important;
}

.mat-mdc-radio-button:not(.mat-mdc-radio-disabled).cdk-program-focused .mat-mdc-radio-persistent-ripple {
  opacity: 0 !important;
}

.mat-mdc-radio-button {
  .mdc-form-field {
    font-weight: 500;
  }
}

.tax-menu {
  position: relative;
  top: 4px;
  max-width: unset !important;
}

.toast-top-right {
  top: 64px
}

.tpt-contract-sign-dialog {
  .mat-mdc-dialog-container {
    padding: 0;
  }
}

.toast-container .ngx-toastr {
  color: #151F2B;
  box-shadow: 0 4px 32px rgba(0, 16, 61, 0.16);
  border-radius: 4px;
  padding: 10px 24px 16px 10px;
  background: $white;
  border-left-width: 2px !important;
  font-size: 14px;
  line-height: 16px;
  width: 388px;
  max-width: calc(100vw - 24px);

  .dark {
    color: $gray-darker;
    font-weight: 500;
  }

  svg {
    flex-shrink: 0;
  }

  &:hover {
    box-shadow: 0 4px 32px rgba(0, 16, 61, 0.16);
  }

  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  &.warning {
    border: 1px solid rgba(240, 191, 20, 0.25);
    border-left-color: #F0BF14;

    svg {
      color: #F0BF14;
    }
  }

  &.info {
    border: 1px solid rgba(18, 131, 201, 0.25);
    border-left-color: #1283C9;

    svg {
      color: #1283C9;
    }
  }

  &.success {
    border: 1px solid rgba(35, 161, 71, 0.25);
    border-left-color: #23A147;

    svg {
      color: #23A147;
    }
  }

  &.error {
    border: 1px solid rgba(218, 31, 41, 0.25);
    border-left-color: #DA1F29;

    svg {
      color: #DA1F29;
    }
  }
}



mat-calendar.creative-objects {
  box-shadow: $co-box-shadow !important;

  .mat-calendar-body-selected {
    background-color: $purple-primary !important;
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(161, 144, 254, 0.3);
  }
}

.creative-objects.mat-mdc-select-panel {
  .mat-mdc-option.mat-mdc-selected:not(.mat-mdc-option-disabled) {
    color: $purple-primary;
  }

  .mat-mdc-option:hover:not(.mat-mdc-option-disabled), .mat-mdc-option:focus:not(.mat-mdc-option-disabled) {
    color: $purple-primary;
  }

  box-shadow: $co-box-shadow !important;
}

.mat-mdc-tooltip.purple-bckg {
  color: $white;
  background: #8174CC;
  font-size: 12px;
  padding: 12px 16px;
  width: 250px;
}

.mat-elevation-z8 {
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05) !important;
}

.header-profile-menu {
  width: 300px;
  position: relative;
  top: 8px;

}

mat-slide-toggle label, .mat-radio-button, .mat-stepper-horizontal, .mat-mdc-button, .mat-mdc-tab-group, .mat-mdc-table,
.mat-tab-label, .mat-option, .mat-card, .mat-mdc-menu-item, .mat-mdc-checkbox, .mat-toolbar, .mat-mdc-dialog-container {
  font-family: 'Manrope', 'Inter', sans-serif !important;
}

.mat-calendar-body-cell {
  max-height: 40px;
}

.mat-mdc-tooltip.disabled-button-tooltip {
  color: $white;
  background: $green-dark;
  font-size: 12px;
  padding: 12px 16px;
  width: 250px;
}

.mat-datepicker-content {
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05) !important;

  .mat-calendar {
    font-family: 'Manrope', 'Inter', sans-serif !important;
  }

  .mat-calendar-body-cell {
    font-size: 14px;
    font-weight: 600;
  }
}

/*
mat-checkbox
*/

.mat-mdc-checkbox {

  //.mdc-checkbox__background {
  //  border-width: 2px !important;
  //  border-radius: 4px;
  //  border-color: #D0D5DD;
  //}
  //
  //.mat-mdc-checkbox-ripple {
  //  display: none;
  //}


}


.docs-menu {
  max-width: 360px !important;
}

.autocomplete-panel {
  background: $white;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 8px;
  width: 100%;
  max-height: 220px;
  overflow-y: auto;
}

.mat-mdc-form-field-error, .mat-hint {
  font-size: 12px;
}

.mat-tab-body-content[style*="visibility: hidden"] {
  display: block !important;
}

.mat-tab-body-content {
  visibility: visible !important;
}

.custom-tooltip {
  background: #101828 !important;
  font-family: 'Manrope', 'Inter', sans-serif !important;
  font-weight: 600;
  font-size: 12px !important;
  max-width: 350px !important;
  border-radius: 4px;
}

.app-add-executor-dialog .mat-dialog-container {
  display: flex;
  flex-direction: column;
}

.red-asterisk {
  color: #DB495C;
}

.overflow-hidden {
  overflow: hidden;
}

.emp-task-details-dialog, .fr-task-details-dialog {
  .mat-mdc-dialog-container {
    @include mobile-down {
      padding: 16px;
    }
  }
}

.mat-mdc-menu-panel.menu-dark-tooltip {
  padding: 8px 12px;
  border-radius: 8px;
  background: $gray-900;
  left: 50%;
  min-height: 40px !important;
  bottom: 8px;

  .mat-menu-content {
    padding: 0;
  }
}

.cursor-pointer {
  cursor: pointer;
}
