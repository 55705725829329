@import "variables";

$fontWeights: (400, 500, 600, 700, 800);

@each $fw in $fontWeights {
  .fw-#{$fw} {
    font-weight: #{$fw};
  }
}

$fontSizes: (10, 12, 14, 16, 18, 20, 22, 24, 28, 32, 36, 40);

@each $fs in $fontSizes {
  .fs-#{$fs} {
    font-size: #{$fs}px;
  }
}

@each $lh in $fontSizes {
  .lh-#{$lh} {
    line-height: #{$lh}px;
  }
}

@each $w in $fontSizes {
  .w-#{$w} {
    width: #{$w}px !important;
  }
}

$fontColors: (
  100: $gray-100,
  200: $gray-200,
  300: $gray-300,
  400: $gray-400,
  500: $gray-500,
  600: $gray-600,
  700: $gray-700,
  800: $gray-800,
  900: $gray-900);

@each $name, $color in $fontColors {
  .text-gray-#{$name} {
    color: #{$color};
  }
}

.text-center {
  text-align: center;
}

.text-black {
  color: $black;
}

.text-white {
  color: $white;
}

.text-green {
  color: $green-500;
}
