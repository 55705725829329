@import "variables";

.header-menu,
.header-menu-list {
  display: flex;
  align-items: center;
  height: 100%;
}

.header-menu {
  margin-left: 48px;
}

.header-menu-item {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: $gray;
  cursor: pointer;
  outline: 0 none;
  transition: color 300ms;
  height: 100%;

  &:hover {
    color: $green-main;
    transition: color 300ms;

    .header-item-count {
      color: $white;
      background-color: $green-main;
      transition: all 300ms;
    }

    &::after {
      width: 100%;
      transition: all 300ms;
    }
  }

  & + & {
    margin-left: 24px;
  }

  &::after {
    content: ' ';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 0;
    height: 1px;
    background-color: $green-main;
    transition: width 250ms;
  }

  &-active {
    color: $green-main;
  }

  &.header-menu-item-active {
    &::after {
      width: 100%;
      transition: width 250ms;
    }
  }

  @include desktop-down {

    &.hide {
      display: none;
    }
  }
}


.tabs-more {
  display: none;
  font-size: 14px;
  line-height: 16px;
  color: $gray;
  cursor: pointer;
  font-weight: 400;
  margin-left: 24px;
}

@include desktop-down {
  .tabs-more {
    display: flex;
  }
}

.header-item-count {
  min-width: 16px;
  height: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  font-size: 10px;
  color: $gray-lightest;
  background-color: #f1f2f4;
  border-radius: 5px;
  transition: all 300ms;

  .header-menu-item-active & {
    color: $white;
    background-color: $green-dark;
  }
}
